import React from 'react';
import PropTypes from 'prop-types';

import theme from 'styles/theme';

import { Container, InformationContainer, Title, WorkType, Subtitle } from './styles';

const MissingDataCard = ({ obj, reqFields = [], maxWidthText = '30rem', height = '15rem' }) => {
  let card = {
    errorType: 'Error Displaying Card Information',
    title: 'Invalid object',
    subtitle: 'One or more fields are invalid.',
    invalidFields: [],
    validFields: [],
  };
  const sortFields = (obj, reqFields) => {
    obj &&
    Object.entries(obj).map(([k, v]) => {
      if (reqFields.includes(k) && v === (undefined || null)) {
        card.invalidFields.push({ key: k, value: v });
      } else if (reqFields.includes(k)) {
        card.validFields.push({ key: k, value: v });
      }
    });
  };

  sortFields(obj, reqFields);

  return (
    <Container height={height}>
      <InformationContainer maxWidthText={maxWidthText}>
        <WorkType color={theme.newTheme.color.error}>
          <b>{card.errorType}</b>
        </WorkType>
        <Title>{card.title}</Title>

        {card.invalidFields.length &&
          card.invalidFields.map(field => (
            <WorkType color={theme.newTheme.color.error}>{`${field.key}: ${field.value}`}</WorkType>
          ))}
        {card.validFields.length &&
          card.validFields.map(field => (
            <WorkType color={theme.newTheme.color.service.consultancy.dark}>
              {`${field.key}:  ${field.value}`}
            </WorkType>
          ))}
      </InformationContainer>
    </Container>
  );
};

MissingDataCard.propTypes = {
  obj: PropTypes.object.isRequired,
};

export default MissingDataCard;
