/**
 * param { post } object for templates/post.js Post
 *
 * @return {boolean} returns false when any of the required fields is missing, null or undefined
 */
export const validateCaseStudyEntry = caseS => {
  const requiredFields = ['title', 'slug', 'LimitedAbstract', 'thumbnail'];

  const getInvalidField = obj => {
    const invalidFields = joiTestCaseStEntry(obj);
    return invalidFields.error ?? null;
  };

  const validateCS = obj => {
    if (obj) {
      if (getInvalidField(obj)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return validateCS(caseS, requiredFields);
};

export const validateCaseStudy = caseS => {
  const checkFields = obj => {
    const checkedCS = joiTestCaseSt(obj);
    return checkedCS.error ? checkedCS : obj;
  };

  const validateCS = obj => {
    if (obj) {
      return checkFields(obj);
    } else {
      return false;
    }
  };

  return validateCS(caseS);
};

export const validateBlogPostEntry = post => {
  const requiredFields = [
    'title',
    'subtitle',
    'slug',
    'excerpt',
    'publishedDate',
    'hero',
    'category',
  ];
  const reqObjects = ['excerpt', 'hero', 'category'];

  const getInvalidObj = field => {
    switch (field) {
      case 'excerpt':
        validValue('excerpt', post['excerpt']);
        break;

      case 'hero':
        validValue('contentful_id', post['hero']);
        break;

      case 'category':
        validValue('name', post['category']);
        validValue('slug', post['category']);
        break;

      default:
        break;
    }
  };

  const validValue = (field, obj) => {
    if (field in obj) {
      if ((typeof obj[field] || obj[field]) != (undefined || null || 'undefined' || '')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getInvalidFields = (obj, reqFields) => {
    let invalidfields = Object.entries(obj).filter(([k, v]) => {
      return reqFields.includes(k) && v === (undefined || null);
    });
    let invalidObj = Object.entries(obj).filter(([k, v]) => {
      reqObjects.includes(k) && v != (undefined || null) && getInvalidObj(k, obj[k]);
    });
    invalidfields.push(...invalidObj);
    return invalidfields;
  };

  const validatePost = (obj, reqFields) => {
    if (obj) {
      const invalidfields = getInvalidFields(obj, reqFields);
      if (invalidfields.length) {
        return false;
      } else if (true) {
        return true;
      }
    } else {
      return false;
    }
  };

  return validatePost(post, requiredFields);
};

export const validateBlogPost = post => {
  const checkFields = obj => {
    const checkedCS = joiTestBlogPost(obj);
    return checkedCS.error ? checkedCS : post;
  };

  const validatePost = obj => {
    if (obj) {
      return checkFields(obj);
    } else {
      return false;
    }
  };
  return validatePost(post);
};

const joiTestCaseStEntry = obj => {
  const Joi = require('joi');

  const schema = Joi.object({
    slug: Joi.string().required(),
    LimitedAbstract: Joi.string().required(),
    filteredIn: Joi.any(),
    subtitle: Joi.string(),
    hero: Joi.any(),
    body: {
      json: Joi.object().required(),
    },
    technologies: Joi.any(),
    thumbnail: {
      contentful_id: Joi.string().required(),
      title: Joi.string(),
      file: {
        url: Joi.string().required(),
      },
    },
    title: Joi.string().required(),
    featured: Joi.any(),
  });

  return schema.validate(obj);
};

const joiTestCaseSt = obj => {
  const Joi = require('joi');

  const schema = Joi.object({
    slug: Joi.string().required(),
    LimitedAbstract: Joi.string().required(),
    filteredIn: Joi.any(),
    subtitle: Joi.string().required(),
    description: Joi.string().required(),
    metaTagImage: {
      contentful_id: Joi.string(),
      file: {
        url: Joi.string().required(),
      },
    },
    hero: {
      title: Joi.string().required(),
      contentful_id: Joi.string().required(),
    },
    body: {
      json: Joi.object().required(),
    },
    technologies: Joi.any(),
    thumbnail: {
      contentful_id: Joi.string().required(),
      title: Joi.string(),
      file: {
        url: Joi.string().required(),
      },
    },
    title: Joi.string().required(),
  });

  return schema.validate(obj);
};

const joiTestBlogPost = obj => {
  const Joi = require('joi');

  const schema = Joi.object({
    htmlTitleTag: Joi.string().allow(null),
    slug: Joi.string().required(),
    subtitle: Joi.string().required(),
    category: {
      slug: Joi.string().required(),
      name: Joi.string().required(),
    },
    excerpt: {
      excerpt: Joi.string().required(),
    },
    hero: {
      title: Joi.string().required(),
      contentful_id: Joi.string().required(),
      file: {
        url: Joi.string().required(),
      },
    },
    author: {
      fullName: Joi.string().required(),
      thumbnail: {
        contentful_id: Joi.string().required(),
      },
    },
    publishedDate: Joi.string().required(),
    body: {
      json: Joi.object().required(),
    },
    technologies: Joi.any(),
    title: Joi.string().required(),
    faQs: Joi.any(),
  });

  return schema.validate(obj);
};
