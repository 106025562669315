import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.newTheme.spacing(5)} 0;
  border-radius: ${({ theme }) => theme.newTheme.border.radius.medium};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.newTheme.shadow.main};
  height: ${props => props.height};

  padding: 0 1rem;
  &:hover {
    h5 {
      text-decoration: underline;
    }

    @keyframes zoom {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(1.05);
      }
    }

    img {
      animation: zoom 0.3s ease-in-out 0s 1;
      transform: scale(1.05);
    }

    svg {
      transition: ${({ theme }) => theme.newTheme.transition.main};
      transform: translateY(10px);
    }
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    height: inherit;
  }

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    flex-direction: column;
    height: inherit;
  }
`;

export const WorkType = styled.h6`
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  font-size: ${({ theme }) => theme.newTheme.font.text2.size};
  line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  color: ${props => props.color};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  b{
    font-weight: ${({ theme }) => theme.newTheme.font.weight.medium};
    color: ${props => props.color};
  }
`;

export const Title = styled.h5`
  color: ${({ theme }) => theme.newTheme.color.grey.main};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.medium};
  font-size: ${({ theme }) => theme.newTheme.font.text2.size};
  line-height: ${({ theme }) => theme.newTheme.font.text2.lineHeight};
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;

  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.tablet}) {
    margin: ${({ theme }) => theme.newTheme.spacing(5)} 0;
  }
`;

export const Subtitle = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.newTheme.color.grey.main};
  font-weight: ${({ theme }) => theme.newTheme.font.weight.regular};
  font-size: ${({ theme }) => theme.newTheme.font.text3.size};
  line-height: ${({ theme }) => theme.newTheme.font.text3.lineHeight};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.newTheme.spacing(6)};
  max-width: ${props => props.maxWidthText};
  height: 100%;
  @media only screen and (${({ theme }) => theme.newTheme.breakpoints.mobile}) {
    max-width: 100%;
    flex-grow: 1;
    ${Subtitle} {
      display: none;
    }
  }
`;

